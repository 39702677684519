<template>
  <!-- 전체보기(Class) : allView -->
  <div :class='[
    "container",
    isWeeksCalendar ? "weeksCalendar" : "basicCalendar",
    isAllViewMode && "allView",
  ]'>
    <div class='body-header'>
      <div class='date'>
        <div
          class='prev'
          @click='goToMonth("PREV")'
        >
          <div class='item'>
            {{ $t('common.prev') }}
          </div>
        </div>
        <div class='title'>
          <div
            class='item'
            @click='onOpenDatePicker'
          >
            <strong>{{ currentDate_YYYY_MM }}</strong>
          </div>
          <date-picker
            ref='datePicker'
            @selected='onSelectedDatePicker'
          />
        </div>
        <div
          class='next'
          @click='goToMonth("NEXT")'
        >
          <div class='item'>
            {{ $t('common.next') }}
          </div>
        </div>
      </div>
      <div
        class='caption'
        @click='isRateViewMode = !isRateViewMode'
      >
        <div class='item'>
          ({{ $t(`report.${isRateViewMode ? 'reservationRate' : 'reservationPositiveTeam'}`) }})
        </div>
      </div>
      <div
        class='refresh'
        @click='fetch'
      >
        <div class='item'>
          {{ $t('common.refresh') }}
        </div>
      </div>
    </div>
    <div class='body-content'>
      <div class='content-main'>
        <calendar
          ref='calendar'
          :output='getOutput'
          :is-weeks-calendar='isWeeksCalendar'
          @selectedDate='onSelectedDate'
        />
      </div>
      <div class='content-sub'>
        <div class='sub-header'>
          <div class='header'>
            <div
              class='click-area'
              @click='isReservationTimeListPopupOpened = true'
            />
            <div
              v-if='isReservationClosed'
              class='label'
            >
              {{ $t('common.closed') }}
            </div>
            <div class='title'>
              <strong>{{ currentDate_YYYY_MM_DD }}</strong>
              {{ currentDate_ddd }}
            </div>
            <div
              :class='[isAllViewMode ? "card" : "all"]'
              @click='isAllViewMode = !isAllViewMode'
            >
              <div class='item'>
                {{ $t(`common.${isAllViewMode ? 'allView' : 'cardView'}`) }}
              </div>
            </div>
          </div>
          <div class='body'>
            <div class='contain'>
              <ul class='info'>
                <li v-for='(part, partIndex) in partDivs' :key='partIndex'>
                  <div class='caption'>
                    {{ part.comName }}
                  </div>
                  <div class='content'>
                    {{ getCountOfParts(part.comCode) }}
                  </div>
                </li>
                <li>
                  <div class='caption'>
                    {{ $t('common.total') }}
                  </div>
                  <div class='content'>
                    {{ getCountOfAllReserve }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class='sub-body'>
          <ul class='tab'>
            <!-- 활성(Class) : active -->
            <li
              v-for='(course, courseIndex) in courseCodes' :key='courseIndex'
              :class='[activatedCourseCode === course.comCode && "active"]'
              :style="`
                float: left;
                width: calc(((100% - 0.4px) / ${courseCodes.length}) - 5px);
                margin-right: ${courseIndex === 0 ? '3px' : (courseIndex + 1) === courseCodes.length ? '' : '1.5px'};
                margin-left: ${courseIndex === 0 ? '' : (courseIndex + 1) === courseCodes.length ? '3px' : '1.5px'};
              `"
              @click='activatedCourseCode = course.comCode'
            >
              <div class='item'>
                {{ course.comName }}
              </div>
            </li>
          </ul>
          <ul class='timeList'>
            <!-- 활성(Class) : active -->
            <li
              v-for='(part, partIndex) in partDivs' :key='partIndex'
              :class='partViewIndexList.includes(part.comCode) && "active"'
            >
              <div
                class='list-header'
                @click='onClickPartView(part.comCode)'
              >
                <div class='contain'>
                  <div class='title'>
                    {{ part.comName }}
                  </div>
                  <div class='content'>
                    <strong>{{ teamCountWithCardView(part.comCode) }}</strong>팀
                  </div>
                  <i />
                </div>
              </div>
              <div class='list-body'>
                <div class='list-wrap'>
                  <ul
                    v-for='(row, rowIndex) in timeListWithCardView(part.comCode)' :key='rowIndex'
                    class='list'
                  >
                    <li
                      v-for='(time, timeIndex) in row' :key='timeIndex'
                      @click='onClickColumn(time)'
                    >
                      <div class='contain'>
                        <div class='time'>
                          <div class='item'>
                            {{ time.resveTime }}
                          </div>
                        </div>
                        <div
                          v-if='time.vipFlag'
                          class='vip'
                        >
                          <div class='item'>
                            VIP
                          </div>
                        </div>
                        <div
                          v-if='time.resveRemarks'
                          class='memo'
                        >
                          <div class='item'>
                            {{ $t('common.memo') }}
                          </div>
                        </div>
                        <div class='name'>
                          <div class='item'>
                            {{ time.resveName }}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class='sub-body all'>
          <div class='body'>
            <div
              v-for='(course, courseIndex) in courseCodes' :key='courseIndex'
              :style="`
                float: left;
                width: calc(((100% - 0.4px) / ${courseCodes.length}) - 3px);
                padding-right: ${courseIndex === 0 ? '3px' : (courseIndex + 1) === courseCodes.length ? '' : '1.5px'};
                padding-left: ${courseIndex === 0 ? '' : (courseIndex + 1) === courseCodes.length ? '3px' : '1.5px'};
              `"
            >
              <div class='title'>
                {{ course.comName }}
              </div>
              <div class='table'>
                <div class='table-wrap'>
                  <table v-for='(part, partIndex) in partDivs' :key='partIndex'>
                    <colgroup>
                      <col class='time' />
                      <col class='name' />
                      <col class='remarks' />
                    </colgroup>
                    <tr
                      v-for='(time, timeIndex) in timeListWithSideView(course.comCode, part.comCode)' :key='timeIndex'
                      :class='[time.isSummary && "tfoot",!time.isSummary && "custom-tr",timeIndex===0 && "top-none"]'
                      @click='onClickColumn(time)'
                    >
                      <div v-if="!time.isSummary"
                           class="triangle"
                           :style="reservationPrivileges&&`border-top: 20px solid ${timeColor(time)}`"
                      >
                      </div>
                      <td
                          class='time'
                      >
                        <div class='item'>
                          {{ time.resveTime }}
                        </div>
                        <div
                            v-if='time.vipFlag'
                            class='vip'
                        >
                          <div class='item'>
                            VIP
                          </div>
                        </div>
                      </td>
                      <td class='name'>
                        <div class='contain custom-display'>
                          <div v-if="time.resveRemarks" class="resveRemarks-img">
                          </div>
                          <div class='item'>
                            {{ time.resveName }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <reservation-time-list-popup
        v-if='isReservationTimeListPopupOpened'
        ref='reservationTimeListPopup'
        :current-date='currentDate'
        :course-codes='courseCodes'
        :part-divs='partDivs'
        :reserve-course-list='reserveCourseList'
        :get-count-of-all-reserve='getCountOfAllReserve'
        :get-count-of-parts='getCountOfParts'
        @clickColumn='onClickColumn'
        @submit='onSubmitReservationTimeListPopup'
        @close='onCloseReservationTimeListPopup'
    />
    <!-- 모달 -->
    <div
      v-if='isPopupOpened'
      class='ui-modal'
    >
      <reservation-info-popup
        v-if='isReservationInfoPopupOpened'
        ref='reservationInfoPopup'
        :current-date='currentDate'
        @close='onCloseReservationInfoPopup'
      />
      <reservation-none-info-popup
          v-if='isReservationNoneInfoPopupOpened'
          ref='reservationNoneInfoPopup'
          :current-date='currentDate'
          @fetchList="fetch"
          @close='onCloseReservationNoneInfoPopup'
      />
      <time-check-modal
        v-if='isTimeCheckModalPopupOpend'
        ref='timeCheckModal'
        @submit="webTimeClear"
        @close='onCloseTimeCheckModalPopup'
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { sortBy as _sortBy } from 'lodash';
import { numberWithCommas } from '@/utils/Number';
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetCodeAbrv,
  commonCodesGetColorValue
} from '@/utils/CommonCodeUtil';
import Calendar from '@/components/Calendar';
import DatePicker from '@/components/DatePicker';
import ReservationTimeListPopup from '@/components/Modals/ReservationTimeListPopup';
import ReservationInfoPopup from '@/components/Modals/ReservationInfoPopup';
import commonMixin from '@/mixin/commonMixin';
import GolfErpAPI from '@/api/GolfErpAPI';
import { DATE_FORMAT_YYYY_MM_DD } from '@/utils/Date';
import TimeCheckModal from "@/components/Modals/TimeCheckModal";
import ReservationNoneInfoPopup from "@/components/Modals/ReservationNoneInfoPopup"
import {mapGetters} from "vuex";

export default {
  name: 'ReserveStatus',
  components: {
    TimeCheckModal,
    Calendar,
    DatePicker,
    ReservationTimeListPopup,
    ReservationInfoPopup,
    ReservationNoneInfoPopup
  },
  mixins: [commonMixin],
  data() {
    return {
      currentDate: moment(),
      courseCodes: [],
      partDivs: [],
      calendarList: [],
      reserveCourseList: [],
      activatedCourseCode: '1',
      partViewIndexList: ['1'],
      touchPosition: {
        startY: null,
        endY: null
      },
      isAllViewMode: true,
      isWeeksCalendar: false,
      isRateViewMode: false,
      isReservationTimeListPopupOpened: false,
      isReservationInfoPopupOpened: false,
      isReservationNoneInfoPopupOpened: false,
      isTimeCheckModalPopupOpend: false,
      $viewer: null,
    };
  },
  async created() {
    document.addEventListener('touchstart', this.handleTouchStart, false);
    document.addEventListener('touchmove', this.handleTouchMove, false);
    document.addEventListener('touchend', this.handleTouchEnd, false);
  },
  async mounted() {
    await this.init();
  },
  async beforeDestroy() {
    document.removeEventListener('touchstart', this.handleTouchStart, false);
    document.removeEventListener('touchmove', this.handleTouchMove, false);
    document.removeEventListener('touchend', this.handleTouchEnd, false);
  },
  computed: {
    ...mapGetters(['authList']),
    reservationPrivileges() {
    return this.authList.includes('misResveStatusReg')
    },
    pureCurrentDate() {
      return moment(this.currentDate).locale('ko');
    },
    currentDate_YYYY_MM_DD() {
      return this.pureCurrentDate.format('YYYY.MM.DD');
    },
    currentDate_YYYY_MM() {
      return this.pureCurrentDate.format('YYYY.MM');
    },
    currentDate_ddd() {
      return this.pureCurrentDate.format('(ddd)');
    },
    getCountOfAllReserve() {
      let count = 0;
      this.reserveCourseList
        ?.forEach(course => {
          count += (course
            ?.data
            ?.filter(item => item.totTeamCount)[0]
            ?.totTeamCount) || 0;
        });
      return count;
    },
    getOutput() {
      const month = bsnDate => Number(moment(bsnDate).format('M'));
      const day = bsnDate => Number(moment(bsnDate).format('D'));
      const dayColorValueArray = this.calendarList
        ?.map(item => {
          const block = {};
          block.type = 'COLOR_VALUE';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.style = { color: this.getDayColorValue(item.bsnDate) };
          return block;
        });
      const dayValueArray = this.calendarList
        ?.filter(item => item.allTeamCount)
        ?.map(item => {
          const block = {};
          block.type = 'VALUE';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.value = this.isRateViewMode
            ? `${numberWithCommas(item.allTeamPercent)}%`
            : this.$t('reserveStatus.caption.reserveTeamStatus', [
              numberWithCommas(item.allTeamCountByDivision),
              numberWithCommas(item.allTeamCount)
            ]);
          block.class = { strong: item.allTeamPercent === 100 && 'bold' };
          return block;
        });
      const dayReservationClosedArray = this.calendarList
        ?.map(item => {
          const block = {};
          block.type = 'RESERVATION_CLOSED';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.value = (
            item.allTeamCount > 0 &&
            item.allTeamPercent === 100
          );
          return block;
        });
      const dayClosedArray = this.calendarList
        ?.map(item => {
          const block = {};
          block.type = 'CLOSED';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.value = item.bsnCode === 'CLOSE';
          return block;
        });
      return [
        dayColorValueArray,
        dayValueArray,
        dayReservationClosedArray,
        dayClosedArray
      ];
    },
    isReservationClosed() {
      const todayInfo = this.calendarList
        ?.find(item => moment(item.bsnDate).format('YYYY.MM.DD') === this.currentDate_YYYY_MM_DD);
      return (
        todayInfo?.allTeamCount > 0 &&
        todayInfo?.allTeamPercent === 100
      );
    },
    isPopupOpened() {
      return (
        this.isReservationInfoPopupOpened ||
        this.isTimeCheckModalPopupOpend ||
        this.isReservationNoneInfoPopupOpened
      );
    }
  },

  methods: {
    timeColor(timeData) {
      if(timeData.timeStatus === "BLOCK" || timeData.timeStatus === "HOLD"){
          return  commonCodesGetColorValue("TIME_STATUS",timeData.timeStatus);
      } else if(timeData.cancelDiv ===  "RAIN" || timeData.cancelDiv === "NOSHOW"){
          return  commonCodesGetColorValue("CANCEL_DIV",timeData.cancelDiv);
      } else if(timeData.chkinStatus === "CHKIN"){
          return  commonCodesGetColorValue("CHKIN_STATUS",timeData.chkinStatus);
      } else if (timeData.agncyOpenFlag) {
          return commonCodesGetColorValue("COLOR_TIME_STATUS", "AGNCY_OPEN_FLAG");
      } else if (timeData.webOpenFlag) {
          if (timeData.timeDiv === "OCLUB") {
            return commonCodesGetColorValue("COLOR_TIME_STATUS", "WEB_OCLUB_TIME");
        } else {
            return commonCodesGetColorValue("COLOR_TIME_STATUS", "WEB_OPEN_FLAG");
        }
      } else {
        return 'transparent';
      }
    },

    async init() {
      // TODO : 공통코드 세팅이 created 훅보다 늦게 되는 버그 확인 필요.
      setTimeout(async () => {
        const courseCodes = _sortBy(commonCodesGetCommonCode('COURSE_CODE', true), ["sortNo"]);
        this.activatedCourseCode = courseCodes[0]?.comCode;
        const partDivs = commonCodesGetCommonCode('PART_DIV', true);
        if (courseCodes && partDivs) {
          this.courseCodes = courseCodes;
          this.partDivs = partDivs;
          await this.fetch();
          await this.$nextTick();
          this.$refs.datePicker.setDate(this.currentDate.format(DATE_FORMAT_YYYY_MM_DD), true);
        } else {
          await this.init();
        }
      }, 500);
    },
    getCalendarInfo(day) {
      return this.calendarList?.find(item => item.bsnDate === day);
    },
    getDayColorValue(day) {
      const info = this.getCalendarInfo(moment(day).format('YYYY-MM-DD'));
      const dwCode = info?.dwCode || '';
      const bsnCode = info?.bsnCode || '';
      const color = dwCode === '1' || dwCode === '7'
        ? commonCodesGetColorValue('DW_CODE', dwCode.toString())
        : bsnCode === 'WEEKEND'
          ? commonCodesGetColorValue('BSN_CODE', bsnCode.toString())
          : commonCodesGetColorValue('DW_CODE', dwCode.toString());
      return color !== '#000000' && color;
    },
    goToMonth(to) {
      this.$refs.calendar.goToMonth(to === 'NEXT');
    },
    getCountOfParts(partDiv) {
      let count = 0;
      this.reserveCourseList
        ?.forEach(course => {
          count += (course
            ?.data
            ?.filter(item => item.partDiv === partDiv && item.teamCount)[0]
            ?.teamCount) || 0;
        });
      return count;
    },
    handleTouchStart(args) {
      this.touchPosition.startY = args.touches[0].clientY;
    },
    handleTouchMove(args) {
      this.touchPosition.endY = args.touches[0].clientY;
    },
    handleTouchEnd() {
      if (
        !this.touchPosition.startY ||
        !this.touchPosition.endY
      ) {
        return;
      }
      // const screenHeight = screen.height;
      const startY = this.touchPosition.startY;
      const scrollY = window.scrollY;
      const yDiff = this.touchPosition.startY - this.touchPosition.endY;
      const isUpSwipe = yDiff > 0;
      const distance = Math.abs(yDiff);
      if (
        !this.isReservationTimeListPopupOpened && // 타임 목록 팝업 안 열린 상태
        !this.isWeeksCalendar && // 월 단위 캘린더
        isUpSwipe && // 터치 드래그 올림
        // startY >= screenHeight - 200 && // 화면 밑에서 약 200px
        distance >= 80
      ) {
        this.isWeeksCalendar = true;
      } else if (
        !this.isReservationTimeListPopupOpened &&
        !this.isReservationInfoPopupOpened &&
        this.isWeeksCalendar &&
        isUpSwipe &&
        startY <= 460 - scrollY &&
        distance >= 60
      ) {
        this.isReservationTimeListPopupOpened = true;
      } else if (
        !this.isReservationTimeListPopupOpened &&
        !this.isReservationInfoPopupOpened &&
        this.isWeeksCalendar &&
        !isUpSwipe &&
        startY <= 360 - scrollY &&
        distance >= 20
      ) {
        this.isWeeksCalendar = false;
      } else if (
        this.isReservationTimeListPopupOpened &&
        !this.isReservationInfoPopupOpened &&
        !isUpSwipe &&
        startY <= 200 &&
        distance >= 60
      ) {
        this.isReservationTimeListPopupOpened = false;
      } else if (
        this.isReservationInfoPopupOpened &&
        !isUpSwipe &&
        startY <= 200 &&
        distance >= 60
      ) {
        this.isReservationInfoPopupOpened = false;
      }
      this.touchPosition.startY = null;
      this.touchPosition.endY = null;
    },
    onOpenDatePicker() {
      this.$refs.datePicker.openPicker();
    },
    onSelectedDatePicker(date) {
      this.$refs.calendar.goToDate(date);
    },
    async onSelectedDate(day, isWeeksCalendar = true) {
      this.currentDate = moment(day);
      if (
        !this.isWeeksCalendar &&
        isWeeksCalendar
      ) this.isWeeksCalendar = true;
      await this.fetch();
    },
    async onClickColumn(args) {
      await this.fetch();
      if(!args.isSummary&&!args.blankCheck){
        if(args.timeStatus !== "RESVE" && args.timeStatus !== "EMPTY" && this.reservationPrivileges){
          let status = null
          switch (args.timeStatus || args.cancelDiv || args.chkinStatus){
            case"BLOCK" :
              status = "블럭"
              break;
            case "HOLD" :
              status = "홀드"
              break;
            case "RAIN" :
              status = "우천"
              break;
            case "NOSHOW" :
              status = "노쇼"
              break;
            case "CHKIN" :
              status = "체크인"
              break;
          }
          this.isTimeCheckModalPopupOpend = true
          await this.$nextTick();
          this.$refs.timeCheckModal.putMessage(this.$t("timeStatus.statusMessage",[status]));
          // alert(`해당 타임은 ${status} 상태라 예약하실 수 없습니다`);
          return;
        }
        if(args.resveNo){
            const data = (await GolfErpAPI.fetchReservationTimesDetail({
              timeId: args.timeId,
              resveNo: args.resveNo
            })).map(item => this.filterReserveItem(item));
            this.isReservationInfoPopupOpened = true;
            await this.$nextTick();
            this.$refs.reservationInfoPopup.loadData(data[0]);
        } else if(this.reservationPrivileges){
          if(args.webOpenFlag){
            this.isTimeCheckModalPopupOpend = true
            await this.$nextTick();
            this.$refs.timeCheckModal.putMessage("웹타임 상태를 해제하시겠습니까?",true,args);
            return;
          }
            await GolfErpAPI.timeHoldingMis(args.timeId,"HOLD")
            this.isReservationNoneInfoPopupOpened = true;
            await this.$nextTick();
            this.$refs.reservationNoneInfoPopup.loadData(args);
        }
      }
    },
    async webTimeClear(args) {
      this.onCloseTimeCheckModalPopup();
      await GolfErpAPI.webTimeCancel(args.timeId)
      await this.fetch();
      await GolfErpAPI.timeHoldingMis(args.timeId,"HOLD")
      this.isReservationNoneInfoPopupOpened = true;
      await this.$nextTick();
      this.$refs.reservationNoneInfoPopup.loadData(args);
    },
    filterReserveItem(item) {
      if (item?.reservationConfirm?.reservationMember?.resveName) {
        if (item?.reservationConfirm?.reservationMember?.memberDiv) {
          const prefix = commonCodesGetCodeAbrv(
            'MEMBER_DIV',
            item.reservationConfirm.reservationMember.memberDiv
          );
          const resveName = item.reservationConfirm.reservationMember.resveName;
          item.resveName = prefix
            ? `${prefix} ${resveName}`
            : resveName;
        } else {
          item.resveName = item.reservationConfirm.reservationMember.resveName;
        }
      }
      const reserveMember = item.reservationConfirm?.reservationMember;
      if (reserveMember?.membershipId) {
        const membershipId = reserveMember?.membershipId;
        item.memberImagePath = reserveMember?.reservationLinkMembers
          ?.find(i => i.id === membershipId)
          ?.member
          ?.imageFile
          ?.path;
      }
      item.grpKind = commonCodesGetComName('GRP_KIND', reserveMember?.grpKind);
      item.grpName = reserveMember?.grpName;
      item.memberNo = reserveMember?.memberNo;
      item.memberDiv = commonCodesGetComName('MEMBER_DIV', reserveMember?.memberDiv);
      item.memberGrade = commonCodesGetComName('MEMBER_GRADE', reserveMember?.memberGrade);
      item.contactName = reserveMember?.contactName;
      item.contactTel = reserveMember?.contactTel;
      item.resveKind = commonCodesGetComName('RESVE_KIND', reserveMember?.resveKind);
      item.resveChannel = commonCodesGetComName('RESVE_CHANNEL', reserveMember?.resveChannel);
      item.resveNo = reserveMember?.resveNo;
      item.smsSendCnt = reserveMember?.smsSendCnt;
      item.smsSendFlag = reserveMember?.smsSendFlag;
      item.smsSendDt = reserveMember?.smsSendDt;
      item.insertId = reserveMember?.insertId;
      item.insertDt = reserveMember?.insertDt;
      item.updateId = reserveMember?.updateId;
      item.updateDt = reserveMember?.updateDt;
      item.areaCode = commonCodesGetComName('AREA_CODE', reserveMember?.areaCode);
      item.sexCode = commonCodesGetComName('SEX_CODE', reserveMember?.sexCode);
      item.resveRemarks = item?.reservationConfirm?.reservationMember?.resveRemarks;
      item.resveTimeSlot = item.resveTime?.split(':')[0];
      item.cancelDiv = item?.reservationConfirm?.cancelDiv;
      item.recomendr = item?.reservationConfirm?.reservationMember?.recomendr;
      item.vipFlag = item?.reservationConfirm?.reservationMember?.vipFlag;
      return item;
    },
    async fetch() {
      this.calendarList = await GolfErpAPI.fetchReservationCalendar({
        year: this.currentDate.format('YYYY'),
        month: this.currentDate.format('MM'),
        div: 'RESERVATION'
      });
      const reserveTimeList = _sortBy((await GolfErpAPI.fetchReservationTimes({
        resveDate: this.currentDate.format('YYYY-MM-DD'),
        preResveDate: null,
        blankFlag: false,
        searchValue: null,
        aditTeamFlag: false
      })).map(item => this.filterReserveItem(item)), ['partDiv', 'resveTime']);
      let reserveCourseList = [];
      this.courseCodes.forEach(course => {
        let data = [];
        this.partDivs.forEach(part => {
          const timeData = reserveTimeList
            ?.filter(time => time.resveCourse === course.comCode && time.partDiv === part.comCode);
          if (timeData.length > 0) {
            timeData
              ?.forEach(time => data.push(time));
            const teamCount = timeData?.filter(item => item.reservationConfirm)?.length || 0;
            data.push({
              partDiv: part.comCode,
              resveTime: part.comName,
              resveName: teamCount > 0 ? this.$t('common.team', [teamCount]) : '-',
              teamCount,
              isSummary: true
            });
          }
        });
        const totTeamCount = data
          ?.filter(item => item.teamCount)
          ?.reduce((acc, cur) => acc + cur.teamCount, 0);
        data.push({
          resveTime: this.$t('report.total'),
          resveName: totTeamCount > 0
            ? this.$t('common.team', [totTeamCount])
            : '-',
          totTeamCount,
          isSummary: true,
          isTotalSummary: true
        });
        reserveCourseList.push({
          courseCode: course.comCode,
          data
        });
      });
      this.reserveCourseList = reserveCourseList;
    },
    timeListViewDataOnly(courseCode = this.activatedCourseCode) {
      return (
        this.reserveCourseList
          ?.filter(item => item.courseCode === courseCode)[0]
          ?.data
      );
    },
    timeListWithCardView(partDiv) {
      const viewDataOnly = this.timeListViewDataOnly()
        ?.filter(item => item.partDiv === partDiv && !item.isSummary);
      let timeStack = [];
      const timeSlots = [...new Set(viewDataOnly?.map(item => item.resveTimeSlot))];
      timeSlots?.forEach(timeSlot => {
        timeStack.push(
          viewDataOnly
            ?.filter(item => item.resveTimeSlot === timeSlot)
        );
      });
      return timeStack;
    },
    teamCountWithCardView(partDiv) {
      const viewDataOnly = this.timeListViewDataOnly()
        ?.filter(item => item.partDiv === partDiv && !item.isSummary && !item.isTotalSummary);
      return viewDataOnly?.length;
    },
    timeListWithSideView(courseCode, partDiv) {
      const arr = this.timeListViewDataOnly(courseCode)
          ?.filter(item => item.partDiv === partDiv);
      if(arr.length!==0){
        const table = this.blankTable(arr, partDiv);
        return table;
      }
    },
    blankTable(arr,partDiv){
      let arr2 =  this.reserveCourseList.map(item=>{
        return  item.data.filter(item2 => item2.partDiv===partDiv).length
      })
      const max = Math.max(...arr2)
      let length = arr.length
      while(length<max){
        arr.splice(length-1,0,{
          isSummary:false,
          blankCheck:true
        })
        length++;
      }
      const tfoot =  arr.some(data=>data.isSummary===true)
      if(!tfoot){
        arr[max-1].isSummary = true
      }
      return arr
    },
    async onClickRefresh() {
      await this.fetch();
      this.isWeeksCalendar = false;
    },
    onClickPartView(comCode) {
      this.partViewIndexList.includes(comCode)
        ? this.partViewIndexList = this.partViewIndexList
          ?.filter(viewComCode => viewComCode !== comCode)
        : this.partViewIndexList.push(comCode);
    },
    onShowMemberImageClick(imagePath) {
      if (!imagePath) return;
      this.$viewer = this.$viewerApi({ images: [imagePath] });
    },
    async onSubmitReservationTimeListPopup(toNext) {
      this.currentDate = moment(this.currentDate)
        .add(toNext ? 1 : -1, 'days');
      await this.fetch();
    },
    onCloseReservationTimeListPopup() {
      this.isReservationTimeListPopupOpened = false;
    },
    onCloseReservationInfoPopup() {
      this.isReservationInfoPopupOpened = false;
    },
    onCloseReservationNoneInfoPopup() {
      this.isReservationNoneInfoPopupOpened = false;
    },
    onCloseTimeCheckModalPopup() {
      this.isTimeCheckModalPopupOpend = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$viewer) {
      this.$viewer.destroy();
      this.$viewer = null;
    }
    next();
  }
};
</script>
<style scoped>
.triangle {
  cursor: pointer;
  position: absolute ;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 20px solid transparent;
  border-right: 20px solid transparent;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table tr td {
  padding: 0;
  flex: 1;
  text-align: left;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table col.name {
  width: auto;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table col.time {
  width: 100%;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table:last-child tr.tfoot td:first-child{
  border-radius: 0;
}
.tfoot {
  display: flex;
  flex-flow: column nowrap;
  height: 50px;
}
body.id-reservation .tfoot td{
  background-color: transparent !important;
}
.top-none {
  margin-top: 0 !important;
}
body.id-reservation.light .custom-tr {
  background-color: #fff !important;
}
body.id-reservation .custom-tr {
  background-color: #262a34 !important;
}
body.id-reservation.light .tfoot td {
  background-color: #eef2f5 !important;
}
.custom-tr{
  position: relative;
  display: flex;
  border-radius: 5px;
  flex-flow: column nowrap;
  height: 80px;
  overflow: hidden;
  margin-top: 5px;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.tfoot td {
  text-align: center !important;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table {
  background-color: transparent !important;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table tr td.time {
  position: relative;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table tr td.name {
  text-align: right;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table tr td.remarks{
  text-align: center;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap table tr td.name .contain{
  display: block;
}
body.id-reservation .container .body-content .content-sub .sub-body .body .table .table-wrap {
  min-width: auto;
}
.vip {
  position: absolute;
  top: 0;
  right: 0;
}
.resveRemarks-img{
  width: 20px;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAeCAYAAAA/xX6fAAAAAXNSR0IArs4c6QAAAfBJREFUSEvtljFrU1EUx3/nJVgjUXGxiJtChg4KLn4CN+eAjm5FkFYSIUjiSWMGIYklYzfX4FdwkQ66OGkwgxSDUkVp7YuKqC9H7msD1Sax4m0Q6p3ee5x7f++c///ce4UJD5kwD9GF+hXEFoHDewzvYTInWqmFE4ANcuk5oLk3Leb2tLwDzn+gNx/9GyVttVqJdqf7BDjjIzWDF+mDdjafz38amqEDPut0OwKnPQFXJUplVK9+HFlSVQ3g6BEfwJmZk71sNhvFbbfVfvuwLWLjtF972lc3Pqvq17El1YVaByHjQ0ODdYnIqObejzZNpf7QMC9tIcYq/QPnVa+F+9g0PrQbtsbIkjYajVQYyvR48MYbVf3yJz83FKiqSUukuwInxi1msFIu5k4NYprN5tTaWjR2DoloxcX/tNOYmZSr9QdmnBsHFGNZS7mLLqZ8u3bJjCUgvYuM+3+9tWml9hY4DoQG8XVl20gKpIAg/mbS9AHchESJY6rzH9xjtbo4/a0fNcAub8FfAfNazN33CnSnQ/v5y1lEqoA7cSLM7tI/VHZH1A4Nd6HBjpCB+8AuQHAHbKD/MoHN6s380+2T/GW4qZ+AvcOCG7eK1++JyK+axi71dRF2nlmaSn4vFAqF9VHV8nXVfxSYzZVK+ce/k+UHvIc+SBTMTE0AAAAASUVORK5CYII=);
}
.custom-display{
  display: flex !important;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.custom-display .item {
  flex: 1;
}
.custom-display .item ::after{
  contain: none;
}
</style>
